<template>
  <v-app>
    <v-main
      v-show="!pageLoadingOverlay"
      class="justify-center align-center background"
    >
      <v-container
        v-show="variableResults.isAssessed"
        class="full-height-card p-container"
        fill-height
      >
        <v-row justify="center" align-content="center" class="full-display-row">
          <!-- 画面左半分 -->
          <v-col cols="12" xs="12" sm="10" md="6" lg="6" xl="5" class="mr-xl-3">
            <v-row>
              <v-col>
                <!-- 棒グラフカード -->
                <v-card class="bar-card">
                  <v-container class="bar-container">
                    <!-- メモリ -->
                    <v-row class="bar-head-scale-row">
                      <!-- cols="2" -->
                      <v-col
                        class="bar-head-scale text-right bar-left-col"
                        cols="3"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        0
                      </v-col>
                      <v-col class="bar-head-scale text-right">1</v-col>
                      <v-col class="bar-head-scale text-right">2</v-col>
                      <v-col class="bar-head-scale text-right">3</v-col>
                      <v-col class="bar-head-scale text-right">4</v-col>
                      <v-col class="bar-head-scale text-right">5</v-col>
                      <v-col class="bar-head-scale text-right">6</v-col>
                    </v-row>
                    <!-- CEFR区間 -->
                    <v-row>
                      <!-- cols="2" -->
                      <v-col
                        class="bar-head-cefr-row bar-left-col"
                        cols="3"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      />
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #23CB73;"
                      >
                        A1
                      </v-col>
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #21B4C7;"
                      >
                        A2
                      </v-col>
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #102889;"
                      >
                        B1
                      </v-col>
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #AA125A;"
                      >
                        B2
                      </v-col>
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #D71010;"
                      >
                        C1
                      </v-col>
                      <v-col
                        class="bar-head-cefr-row text-center bar-head-cefr"
                        style="color: #EA9015;"
                      >
                        C2
                      </v-col>
                    </v-row>
                    <!-- 棒グラフ -->
                    <v-row class="bar-row">
                      <!-- cols="2" -->
                      <v-col
                        class="bar-name-score text-center bar-left-col"
                        cols="3"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        Score
                      </v-col>
                      <v-col class="bar-graph-col">
                        <span
                          id="bar"
                          class="bar-graph"
                          :style="{ width: barWidth + '%' }"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>

            <!-- Overall Score/CEFR -->
            <v-row class="mt-xl-10">
              <v-col cols="7" md="6">
                <v-card class="box-neumorphism pa-3 overall-results">
                  <span class="assess-title">Overall</span>
                  <span class="assess-res pl-xl-16 pl-lg-11 pl-md-5 pl-sm-16">
                    {{
                      (
                        Math.ceil(
                          variableResults.resultCefr.cefrOverall.score * 100
                        ) / 100
                      ).toFixed(2)
                    }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="5" md="6">
                <v-card class="box-neumorphism pa-3 overall-results">
                  <span class="assess-title pr-lg-10">CEFR</span>
                  <span
                    class="assess-res pl-lg-16 pl-lg-15 pl-md-10 pl-sm-13"
                    :style="{ color: overallCefrColor }"
                  >
                    {{ variableResults.resultCefr.cefrOverall.cefrLevel }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
            <!-- InteLLA画像 -->
            <v-row>
              <v-col>
                <v-img
                  class="intella-img-desktop"
                  contain
                  :aspect-ratio="1"
                  src="@/assets/AssessmentResults/intella_1200.gif"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- 画面右半分 -->
          <v-col
            cols="12"
            xs="12"
            sm="10"
            md="6"
            lg="6"
            xl="5"
            class="ml-xl-3 right-half"
          >
            <v-row>
              <v-col>
                <!-- タブ -->
                <v-card flat tile color="red" class="box-neumorphism mt-3">
                  <v-tabs-items v-model="activatedTab" class="box-neumorphism">
                    <v-tabs
                      v-model="activatedTab"
                      background-color="transparent"
                      color="#BC1464"
                    >
                      <v-tab key="overall">
                        総合評価
                      </v-tab>
                      <v-tab key="detail">
                        詳細評価
                      </v-tab>
                    </v-tabs>
                    <!-- 総合評価：レーダーチャート -->
                    <v-tab-item
                      key="overall"
                      class="rader-chart-tab-item"
                      :transition="false"
                    >
                      <fast-results-rader-chart
                        v-if="assessResLoaded"
                        :styles="raderDesktopResize"
                        :fast-subcategory-score="variableResults"
                        class="pt-13"
                      />
                      <ul class="rader-labels-ul">
                        <li class="rader-labels-li range-label">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: rangeColor }"
                          >
                            {{ variableResults.resultCefr.range.cefrLevel }}
                          </div>
                          <div class="rader-label-name">
                            表現の豊富さ
                          </div>
                        </li>
                        <li class="rader-labels-li accuracy-label">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: accuracyColor }"
                          >
                            {{ variableResults.resultCefr.accuracy.cefrLevel }}
                          </div>
                          <div class="rader-label-name">
                            文法的正しさ
                          </div>
                        </li>
                        <li class="rader-labels-li fluency-label">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: fluencyColor }"
                          >
                            {{ variableResults.resultCefr.fluency.cefrLevel }}
                          </div>
                          <div class="rader-label-name">
                            流暢さ
                          </div>
                        </li>
                        <li class="rader-labels-li phonology-label mb-3">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: phonologyColor }"
                          >
                            {{ variableResults.resultCefr.phonology.cefrLevel }}
                          </div>
                          <div class="rader-label-name">
                            発音の良さ
                          </div>
                        </li>
                        <li class="rader-labels-li interaction-label">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: interactionColor }"
                          >
                            {{
                              variableResults.resultCefr.interaction.cefrLevel
                            }}
                          </div>
                          <div class="rader-label-name">
                            インタラクティブ性
                          </div>
                        </li>
                        <li class="rader-labels-li coherence-label">
                          <div
                            class="rader-label-cefr"
                            :style="{ color: coherenceColor }"
                          >
                            {{ variableResults.resultCefr.coherence.cefrLevel }}
                          </div>
                          <div class="rader-label-name">
                            一貫性
                          </div>
                        </li>
                      </ul>
                    </v-tab-item>
                    <!-- 詳細評価：説明タブ -->
                    <v-tab-item
                      key="detail"
                      class="pt-5 pt-xl-0 pl-7 pr-6 pl-md-3 pr-md-4 px-lg-8 px-xl-9 details-tab-item"
                      :transition="false"
                    >
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Range
                                </div>
                                <div class="detail-ja-title">
                                  表現の豊富さ
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: rangeColor }"
                            >
                              <div class="detail-cefr-c">
                                {{ variableResults.resultCefr.range.cefrLevel }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{ variableResults.resultCefr.range.description }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr class="my-2 my-xl-4 my-xs-6" />

                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Accuracy
                                </div>
                                <div class="detail-ja-title">
                                  文法的正しさ
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="5"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: accuracyColor }"
                            >
                              <div class="detail-cefr-c">
                                {{
                                  variableResults.resultCefr.accuracy.cefrLevel
                                }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{
                                variableResults.resultCefr.accuracy.description
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr class="my-2" />

                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Fluency
                                </div>
                                <div class="detail-ja-title">
                                  流暢さ
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: fluencyColor }"
                            >
                              <div class="detail-cefr-c">
                                {{
                                  variableResults.resultCefr.fluency.cefrLevel
                                }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{
                                variableResults.resultCefr.fluency.description
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr class="my-2" />

                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Phonology
                                </div>
                                <div class="detail-ja-title">
                                  発音の良さ
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: phonologyColor }"
                            >
                              <div class="detail-cefr-c">
                                {{
                                  variableResults.resultCefr.phonology.cefrLevel
                                }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{
                                variableResults.resultCefr.phonology.description
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr class="my-2" />

                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Interaction
                                </div>
                                <div class="detail-ja-title">
                                  インタラクティブ性
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: interactionColor }"
                            >
                              <div class="detail-cefr-c">
                                {{
                                  variableResults.resultCefr.interaction
                                    .cefrLevel
                                }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{
                                variableResults.resultCefr.interaction
                                  .description
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr class="my-2" />

                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col
                              cols="5"
                              sm="3"
                              md="3"
                              lg="3"
                              xl="3"
                              class="detail-title-p"
                            >
                              <div class="detail-title-c">
                                <div class="detail-en-title">
                                  Coherence
                                </div>
                                <div class="detail-ja-title">
                                  一貫性
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              sm="1"
                              md="1"
                              lg="1"
                              xl="1"
                              class="detail-cefr-p"
                              :style="{ color: coherenceColor }"
                            >
                              <div class="detail-cefr-c">
                                {{
                                  variableResults.resultCefr.coherence.cefrLevel
                                }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              xl="8"
                              class="detail-description"
                            >
                              {{
                                variableResults.resultCefr.coherence.description
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>

            <!-- モバイル版レーダーチャート表示 -->
            <v-row>
              <v-col>
                <v-card
                  class="rader-chart-mobile mt-n6 py-10 box-neumorphism rader-chart-tab-item"
                >
                  <fast-results-rader-chart
                    v-if="assessResLoaded"
                    :styles="raderMobileResize"
                    :fast-subcategory-score="variableResults"
                    class="pt-6"
                  />
                  <ul class="rader-labels-ul">
                    <li class="rader-labels-li range-label">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: rangeColor }"
                      >
                        {{ variableResults.resultCefr.range.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        表現の豊富さ
                      </div>
                    </li>
                    <li class="rader-labels-li accuracy-label">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: accuracyColor }"
                      >
                        {{ variableResults.resultCefr.accuracy.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        文法的正しさ
                      </div>
                    </li>
                    <li class="rader-labels-li fluency-label">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: fluencyColor }"
                      >
                        {{ variableResults.resultCefr.fluency.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        流暢さ
                      </div>
                    </li>
                    <li class="rader-labels-li phonology-label mb-3">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: phonologyColor }"
                      >
                        {{ variableResults.resultCefr.phonology.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        発音の良さ
                      </div>
                    </li>
                    <li class="rader-labels-li interaction-label">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: interactionColor }"
                      >
                        {{ variableResults.resultCefr.interaction.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        インタラクティブ性
                      </div>
                    </li>
                    <li class="rader-labels-li coherence-label">
                      <div
                        class="rader-label-cefr"
                        :style="{ color: coherenceColor }"
                      >
                        {{ variableResults.resultCefr.coherence.cefrLevel }}
                      </div>
                      <div class="rader-label-name">
                        一貫性
                      </div>
                    </li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>

            <!-- 縦長画面版インテラ画像表示 -->
            <v-row>
              <v-col class="intella-img-mobile-col">
                <v-img
                  class="intella-img-mobile"
                  contain
                  :aspect-ratio="1"
                  src="@/assets/AssessmentResults/intella_1200.gif"
                />
              </v-col>
            </v-row>

            <!-- インテラコメント -->
            <v-row>
              <v-col>
                <v-card
                  class="mt-xl-n4 px-5 py-3 px-xl-10 py-xl-7 px-lg-8 py-lg-5 px-md-6 py-md-4 px-sm-6 py-sm-4 box-neumorphism intella-comment"
                >
                  <p class="title-from-intella">From InteLLA</p>
                  <div class="message-from-intella pb-1">
                    {{ variableResults.levelDescription }}
                    {{ variableResults.strength }}
                    {{ variableResults.weakness }}
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <!-- モバイル版詳細表示 -->
            <v-row>
              <v-col>
                <v-card
                  class="details-mobile box-neumorphism pt-5 pt-xl-0 pl-7 pr-6 pl-md-3 pr-md-4 px-lg-8 px-xl-9"
                >
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Range
                            </div>
                            <div class="detail-ja-title">
                              表現の豊富さ
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: rangeColor }"
                        >
                          <div class="detail-cefr-c">
                            {{ variableResults.resultCefr.range.cefrLevel }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{ variableResults.resultCefr.range.description }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr class="my-2 my-xl-4 my-xs-6" />

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Accuracy
                            </div>
                            <div class="detail-ja-title">
                              文法的正しさ
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: accuracyColor }"
                        >
                          <div class="detail-cefr-c">
                            {{ variableResults.resultCefr.accuracy.cefrLevel }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{ variableResults.resultCefr.accuracy.description }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr class="my-2" />

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Fluency
                            </div>
                            <div class="detail-ja-title">
                              流暢さ
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: fluencyColor }"
                        >
                          <div class="detail-cefr-c">
                            {{ variableResults.resultCefr.fluency.cefrLevel }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{ variableResults.resultCefr.fluency.description }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr class="my-2" />

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Phonology
                            </div>
                            <div class="detail-ja-title">
                              発音の良さ
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: phonologyColor }"
                        >
                          <div class="detail-cefr-c">
                            {{ variableResults.resultCefr.phonology.cefrLevel }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{ variableResults.resultCefr.phonology.description }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr class="my-2" />

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Interaction
                            </div>
                            <div class="detail-ja-title">
                              インタラクティブ性
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: interactionColor }"
                        >
                          <div class="detail-cefr-c">
                            {{
                              variableResults.resultCefr.interaction.cefrLevel
                            }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{
                            variableResults.resultCefr.interaction.description
                          }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr class="my-2" />

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col
                          cols="5"
                          sm="3"
                          md="3"
                          lg="3"
                          xl="3"
                          class="detail-title-p"
                        >
                          <div class="detail-title-c">
                            <div class="detail-en-title">
                              Coherence
                            </div>
                            <div class="detail-ja-title">
                              一貫性
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="detail-cefr-p"
                          :style="{ color: coherenceColor }"
                        >
                          <div class="detail-cefr-c">
                            {{ variableResults.resultCefr.coherence.cefrLevel }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                          lg="8"
                          xl="8"
                          class="detail-description"
                        >
                          {{ variableResults.resultCefr.coherence.description }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="pageLoadingOverlay">
      <v-progress-circular
        indeterminate
        color="#1791b1"
        size="100"
        width="10"
      />
    </v-overlay>
  </v-app>
</template>
<script>
import { database } from '@/plugins/firebase'
import FastResultsRaderChart from '@/components/charts/FastResultsRaderChart.vue'
export default {
  name: 'FastAssessmentResults',
  components: {
    FastResultsRaderChart,
  },
  data: () => {
    return {
      pageLoadingOverlay: true,
      variableResults: {
        userId: null,
        resultCefr: {
          cefrOverall: {
            score: null,
            cefrLevel: null,
          },
          accuracy: {
            score: null,
            cefrLevel: null,
            description: null,
          },
          coherence: {
            score: null,
            cefrLevel: null,
            description: null,
          },
          fluency: {
            score: null,
            cefrLevel: null,
            description: null,
          },
          interaction: {
            score: null,
            cefrLevel: null,
          },
          phonology: {
            score: null,
            cefrLevel: null,
            description: null,
          },
          range: {
            score: null,
            cefrLevel: null,
            description: null,
          },
        },
        levelDescription: null,
        strength: null,
        weakness: null,
        isAssessed: false,
        isInterviewed: null,
      },
      overallScore: 0,
      dbError: false,
      assessResLoaded: false,
      resultRef: null,
      rangeColor: '',
      accuracyColor: '',
      fluencyColor: '',
      phonologyColor: '',
      interactionColor: '',
      coherenceColor: '',
      overallCefrColor: '',
      activatedTab: 'overall',
      barWidth: 0,
      errorSnackbar: false,
      errorMessage: '',
      raderDesktopHeight: 80,
      raderMobileHeight: 90,
      isMobile: false,
    }
  },
  computed: {
    raderDesktopResize: function() {
      return {
        height: `${this.raderDesktopHeight}%`,
        position: 'relative',
      }
    },
    raderMobileResize: function() {
      return {
        height: `${this.raderMobileHeight}%`,
        position: 'relative',
      }
    },
  },
  mounted() {
    this.assessResLoaded = false
    this.resultRef = database.ref(`results/${this.$route.params.id}`)
    this.resultRef.on('value', snapshot => {
      if (snapshot.exists()) {
        const result = snapshot.val()
        if (result.isAssessed) {
          this.assessResLoaded = true
          this.$set(this, 'variableResults', result)
          this.pageLoadingOverlay = false
          this.resultRef.off()
        }
        this.assessResLoaded = true
        this.variableResults = snapshot.val()
        this.pageLoadingOverlay = false
        this.barWidth =
          this.variableResults.resultCefr.cefrOverall.score * (100 / 6)

        if (this.variableResults.resultCefr.cefrOverall.score <= 1) {
          document.getElementById('bar').classList.add('barColorA1')
        } else if (
          this.variableResults.resultCefr.cefrOverall.score > 1 &&
          this.variableResults.resultCefr.cefrOverall.score <= 2
        ) {
          document.getElementById('bar').classList.add('barColorA2')
        } else if (
          this.variableResults.resultCefr.cefrOverall.score > 2 &&
          this.variableResults.resultCefr.cefrOverall.score <= 3
        ) {
          document.getElementById('bar').classList.add('barColorB1')
        } else if (
          this.variableResults.resultCefr.cefrOverall.score > 3 &&
          this.variableResults.resultCefr.cefrOverall.score <= 4
        ) {
          document.getElementById('bar').classList.add('barColorB2')
        } else if (
          this.variableResults.resultCefr.cefrOverall.score > 4 &&
          this.variableResults.resultCefr.cefrOverall.score <= 5
        ) {
          document.getElementById('bar').classList.add('barColorC1')
        } else if (
          this.variableResults.resultCefr.cefrOverall.score > 5 &&
          this.variableResults.resultCefr.cefrOverall.score <= 6
        ) {
          document.getElementById('bar').classList.add('barColorC2')
        }

        this.rangeColor = this.raderCefrColoring(
          this.variableResults.resultCefr.range.cefrLevel
        )
        this.accuracyColor = this.raderCefrColoring(
          this.variableResults.resultCefr.accuracy.cefrLevel
        )
        this.fluencyColor = this.raderCefrColoring(
          this.variableResults.resultCefr.fluency.cefrLevel
        )
        this.phonologyColor = this.raderCefrColoring(
          this.variableResults.resultCefr.phonology.cefrLevel
        )
        this.interactionColor = this.raderCefrColoring(
          this.variableResults.resultCefr.interaction.cefrLevel
        )
        this.coherenceColor = this.raderCefrColoring(
          this.variableResults.resultCefr.coherence.cefrLevel
        )
        this.overallCefrColor = this.raderCefrColoring(
          this.variableResults.resultCefr.cefrOverall.cefrLevel
        )
      } else {
        this.errorMessage = 'データベース接続エラーが発生しました'
        this.errorSnackbar = true
      }
    })
  },
  beforeDestroy() {
    this.resultRef.off()
  },
  methods: {
    raderCefrColoring: function(cefr) {
      switch (cefr) {
        case 'A1':
          return '#23CB73'
        case 'A2':
          return '#21B4C7'
        case 'B1':
          return '#102889'
        case 'B2':
          return '#AA125A'
        case 'C1':
          return '#D71010'
        case 'C2':
          return '#EA9015'
        default:
      }
    },
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.full-height-card {
  min-height: 100vh;
}

.background {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  opacity: 1;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff;
  opacity: 1;
}
.box-neumorphism {
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff;
  opacity: 1;
}

/* 棒グラフ */
.bar-head-scale {
  padding-right: 0;
  padding-bottom: 0;
  color: #9fa0a2;
  font-weight: bold;
  font-family: 'Helvetica Neue';
  letter-spacing: 0px;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
}
.bar-head-cefr-row {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  border-right: thin solid #9fa0a2;
  color: #9fa0a2;
  font-weight: bold;
  font-family: 'Helvetica Neue';
  letter-spacing: 0px;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
}
.bar-head-cefr-row:after {
  content: '';
  display: block;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 120%;
  border-top: 6px solid #f4f4f4;
}
.bar-graph-col {
  padding-right: 0;
  padding-left: 0;
}
.bar-name-score {
  font-family: 'Roboto Condensed';
  font-weight: bold;
  letter-spacing: 0px;
  color: #222222;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
  float: left;
}
.bar-graph {
  float: left;
  -webkit-animation: show-bar-four 1.2s 0.1s forwards;
  -moz-animation: show-bar-four 1.2s 0.1s forwards;
  animation: show-bar-four 1.2s 0.1s forwards;
}
@keyframes show-bar-four {
  0% {
    width: 0;
  }
}
.barColorA1 {
  background: linear-gradient(90deg, #25d67a, #25d67a);
}
.barColorA2 {
  background: linear-gradient(90deg, #25d67a, #23bbcf);
}
.barColorB1 {
  background: linear-gradient(90deg, #25d67a, #23bbcf, #1430a3);
}
.barColorB2 {
  background: linear-gradient(90deg, #25d67a, #23bbcf, #1430a3, #b3135f);
}
.barColorC1 {
  background: linear-gradient(
    90deg,
    #25d67a,
    #23bbcf,
    #1430a3,
    #b3135f,
    #e61212
  );
}
.barColorC2 {
  background: linear-gradient(
    90deg,
    #25d67a,
    #23bbcf,
    #1430a3,
    #b3135f,
    #e61212,
    #f49616
  );
}

/* 総合結果 */
.overall-results {
  width: 100%;
  float: left;
}
.assess-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: #222222;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
  float: left;
}
.assess-res {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
  float: left;
}

/* 画面右半分 */

/* レーダチャート */
.rader-chart-tab-item {
  position: relative;
}
fast-results-rader-chart {
  background-color: transparent;
}
.rader-labels-ul {
  list-style-type: none;
  letter-spacing: 0px;
  opacity: 1;
  text-align: center;
}
.rader-labels-li {
  position: absolute;
  transform-origin: center;
}
.rader-label-name {
  font-weight: bold;
  font-family: 'PingFang HK';
  color: '#292929';
}
.rader-label-cefr {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
}
/* 説明タブ */
.details-tab-item {
  overflow-y: scroll;
  overflow-x: hidden;
}
.detail-title-p {
  position: relative;
}
.detail-title-c {
  position: absolute;
  top: 30%;
}
.detail-en-title {
  font-family: 'Roboto Condensed';
  font-weight: bold;
  letter-spacing: 0px;
  color: #222222;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
}
.detail-ja-title {
  font-family: 'Hiragino Maru Gothic ProN';
  font-weight: normal;
  letter-spacing: 0px;
  color: #202020;
  opacity: 1;
}
.detail-cefr-p {
  position: relative;
  margin-left: -25px;
  margin-right: 15px;
}
.detail-cefr-c {
  text-align: center;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  letter-spacing: 0px;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
  position: absolute;
  top: 30%;
}
.detail-description {
  font-family: 'Hiragino Maru Gothic ProN';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
hr {
  height: 2px;
  border: none;
  background: linear-gradient(
    90deg,
    #25d67a,
    #23bbcf,
    #1430a3,
    #b3135f,
    #e61212,
    #f49616
  );
}

/* モバイル表示用レーダチャート */
.rader-chart-mobile {
  display: none;
}

/* モバイル表示用インテラ画像 */
.intella-img-mobile {
  display: none;
}

/* インテラコメント */
.title-from-intella {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: #bc1464;
  text-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 0.3em;
  text-decoration-color: #bc1464;
}
.message-from-intella {
  font-family: 'Hiragino Maru Gothic ProN';
  letter-spacing: 0px;
  color: #212121;
}

/* モバイル表示用詳細 */
.details-mobile {
  display: none;
}

/* Desktop First */
@media screen and (max-width: 2560px) {
  /* 4k */
  .p-container {
    max-width: 98%;
  }
  .bar-card {
    height: 240px;
    padding: 0px 40px 5px 0px;
    margin-top: 12px;
  }
  .bar-head-scale-row {
    padding-top: 20px;
    padding-left: 5px;
    margin-right: -19px;
    margin-bottom: -15px;
  }
  .bar-head-scale {
    font-size: 23px;
  }
  .bar-head-cefr-row {
    font-size: 35px;
    height: 45px;
  }
  .bar-row {
    margin-top: 30px;
  }
  .bar-name-score {
    font-size: 2.5em;
  }
  .bar-graph {
    border-radius: 30px;
    height: 100%;
  }
  .overall-results {
    height: 180px;
  }
  .assess-title {
    font-size: 2.5em;
    margin-top: 55px;
    margin-left: 30px;
  }
  .assess-res {
    font-size: 6.5em;
  }
  .intella-img-desktop {
    margin-top: 10px;
    height: 78%;
  }
  .v-tabs {
    height: 150px;
    padding-top: 30px;
    padding-left: 30px;
  }
  .v-tabs-bar {
    height: 60%;
  }
  .v-tab {
    font-size: 2em;
  }
  .v-tabs-slider-wrapper {
    width: 170px;
  }
  .rader-chart-tab-item {
    height: 780px;
  }
  .rader-label-cefr {
    font-size: 4em;
    margin-bottom: -15px;
  }
  .rader-label-name {
    font-size: 23px;
  }
  .rader-labels-li {
    top: 36%;
  }
  .range-label {
    right: 42.7%;
    transform: translate(0px, -370px);
  }
  .accuracy-label {
    right: 42.7%;
    transform: translate(350px, -150px);
  }
  .fluency-label {
    right: 46%;
    transform: translate(-350px, -150px);
  }
  .phonology-label {
    right: 44%;
    transform: translate(0px, 350px);
  }
  .interaction-label {
    right: 39%;
    transform: translate(-350px, 150px);
  }
  .coherence-label {
    right: 46%;
    transform: translate(350px, 150px);
  }
  .details-tab-item {
    height: 780px;
  }
  .detail-en-title {
    font-size: 2.5em;
  }
  .detail-ja-title {
    font-size: 21px;
  }
  .detail-cefr-c {
    font-size: 4em;
  }
  .detail-description {
    font-size: 1.7em;
    padding-right: 12px;
  }
  .intella-comment {
    margin-top: -16px;
  }
  .title-from-intella {
    font-size: 2.5em;
    margin-bottom: 25px;
  }
  .message-from-intella {
    font-size: 2em;
    line-height: 43px;
  }
}
@media screen and (max-width: 1440px) {
  /* Laptop L */
  .full-display-row {
    padding-top: 1%;
  }
  .p-container {
    max-width: 90%;
  }
  .bar-card {
    height: 120px;
    padding: 0px 30px 5px 0px;
    margin-top: 12px;
  }
  .bar-head-scale-row {
    padding-top: 5px;
    padding-left: 5px;
    margin-right: -16px;
    margin-bottom: -15px;
  }
  .bar-head-scale {
    font-size: 15px;
  }
  .bar-head-cefr-row {
    height: 20px;
    font-size: 18px;
  }
  .bar-row {
    margin-top: 15px;
  }
  .bar-name-score {
    font-size: 1.3em;
  }
  .bar-graph {
    border-radius: 12px;
    height: 25px;
  }
  .overall-results {
    height: 94px;
  }
  .assess-title {
    font-size: 1.3em;
    margin-top: 23px;
  }
  .assess-res {
    font-size: 3em;
  }
  .intella-img-desktop {
    margin-top: 15px;
    height: 68%;
  }
  .v-tabs {
    height: 80px;
    padding-top: 0px;
    padding-left: 0px;
  }
  .v-tabs-bar {
    height: 100%;
  }
  .v-tab {
    font-size: 1rem;
  }
  .rader-chart-tab-item {
    height: 450px;
  }
  .rader-label-cefr {
    font-size: 2.2em;
  }
  .rader-label-name {
    font-size: 13px;
  }
  .rader-labels-li {
    top: 39%;
  }
  .range-label {
    right: 43.3%;
    transform: translate(0px, -195px);
  }
  .accuracy-label {
    right: 43.3%;
    transform: translate(185px, -90px);
  }
  .fluency-label {
    right: 46.6%;
    transform: translate(185px, 90px);
  }
  .phonology-label {
    right: 44.3%;
    transform: translate(0px, 190px);
  }
  .interaction-label {
    right: 39.7%;
    transform: translate(-185px, 90px);
  }
  .coherence-label {
    right: 46.6%;
    transform: translate(-185px, -90px);
  }
  .details-tab-item {
    height: 450px;
  }
  .detail-en-title {
    font-size: 1.4em;
  }
  .detail-ja-title {
    font-size: 12px;
  }
  .detail-cefr-c {
    font-size: 2.5em;
  }
  .detail-description {
    font-size: 1em;
    padding-right: 0px;
  }
  .intella-comment {
    margin-top: -46px;
  }
  .title-from-intella {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .message-from-intella {
    font-size: 1.1em;
    line-height: 26px;
  }
}
@media screen and (max-width: 1024px) {
  /* Laptop */
  .p-container {
    max-width: 96%;
  }
  .bar-name-score {
    font-size: 1.1em;
  }
  .overall-results {
    height: 90px;
  }
  .assess-title {
    font-size: 1.2em;
  }
  .assess-res {
    font-size: 2.7em;
  }
  .intella-img-desktop {
    margin-top: 13px;
    height: 69%;
  }
  .v-tabs {
    height: 60px;
  }
  .v-tab {
    font-size: 0.9em;
  }
  .rader-chart-tab-item {
    height: 380px;
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .rader-label-cefr {
    font-size: 2em;
  }
  .rader-label-name {
    font-size: 12px;
  }
  .rader-labels-li {
    top: 42%;
  }
  .range-label {
    right: 42.5%;
    transform: translate(0px, -160px);
  }
  .accuracy-label {
    right: 41%;
    transform: translate(147px, -70px);
  }
  .fluency-label {
    right: 45.4%;
    transform: translate(147px, 70px);
  }
  .phonology-label {
    right: 43.5%;
    transform: translate(0px, 150px);
  }
  .interaction-label {
    right: 38%;
    transform: translate(-147px, 70px);
  }
  .coherence-label {
    right: 45.4%;
    transform: translate(-147px, -70px);
  }
  .details-tab-item {
    height: 380px;
  }
  .detail-en-title {
    font-size: 1.2em;
  }
  .detail-ja-title {
    font-size: 10px;
  }
  .detail-cefr-p {
    margin-left: -20px;
    margin-right: 10px;
  }
  .detail-cefr-c {
    font-size: 2.2em;
  }
  .detail-description {
    font-size: 0.85em;
  }
  .title-from-intella {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  .message-from-intella {
    font-size: 0.9em;
    line-height: 21px;
  }
}
@media screen and (max-width: 768px) {
  /* Tablet */
  .full-display-row {
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .assess-title {
    font-size: 1.3em;
  }
  .assess-res {
    font-size: 3em;
  }
  .intella-img-desktop {
    display: none;
  }
  .right-half {
    margin-top: -35px;
  }
  .rader-chart-tab-item {
    height: 540px;
  }
  .v-tabs {
    height: 70px;
  }
  .rader-label-cefr {
    font-size: 2.2em;
  }
  .rader-label-name {
    font-size: 13px;
  }
  .rader-labels-li {
    top: 39%;
  }
  .range-label {
    right: 43.5%;
    transform: translate(0px, -220px);
  }
  .accuracy-label {
    right: 43.5%;
    transform: translate(220px, -120px);
  }
  .fluency-label {
    right: 46.7%;
    transform: translate(220px, 120px);
  }
  .phonology-label {
    right: 44.7%;
    transform: translate(0px, 230px);
  }
  .interaction-label {
    right: 40.5%;
    transform: translate(-220px, 120px);
  }
  .coherence-label {
    right: 46.7%;
    transform: translate(-220px, -120px);
  }
  .details-tab-item {
    height: 540px;
  }
  .detail-description {
    font-size: 0.9em;
  }
  .intella-img-mobile-col {
    height: 400px;
  }
  .message-from-intella {
    font-size: 1em;
    line-height: 24px;
  }
  .intella-img-mobile {
    display: block;
    margin-top: 13px;
    height: 100%;
  }
}
@media screen and (max-width: 425px) {
  /* Mobile L */
  .full-display-row {
    padding-top: 0%;
    padding-bottom: 10%;
  }
  .p-container {
    max-width: 100%;
  }
  .bar-card {
    padding: 5px 30px 0px 0px;
  }
  .bar-left-col {
    padding-right: 0px;
  }
  .bar-head-scale-row {
    padding-top: 5px;
    padding-left: 5px;
    margin-right: -15px;
    margin-bottom: -15px;
  }
  .bar-head-scale {
    font-size: 11px;
  }
  .bar-head-cefr-row {
    height: 15px;
    font-size: 14px;
    padding-top: 1px;
  }
  .bar-name-score {
    font-size: 1.1em;
  }
  .assess-title {
    font-size: 1.1em;
    margin-top: 20px;
    margin-left: 3px;
    margin-right: 30px;
  }
  .assess-res {
    font-size: 2.5em;
  }

  .v-tabs-items {
    display: none;
  }

  /* モバイル表示用レーダチャート */
  .rader-chart-mobile {
    display: block;
  }

  .details-tab-bar {
    display: none;
  }
  .v-tabs {
    height: 50px;
  }
  .rader-chart-tab-item {
    height: 350px;
  }
  .rader-label-cefr {
    font-size: 2.2em;
  }
  .rader-label-name {
    font-size: 11px;
  }
  .rader-label-cefr {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.7em;
    font-style: normal;
    font-weight: bold;
    margin-bottom: -9px;
  }
  .rader-labels-li {
    top: 43%;
  }
  .range-label {
    right: 42%;
    transform: translate(0px, -140px);
  }
  .accuracy-label {
    right: 43%;
    transform: translate(140px, -55px);
  }
  .fluency-label {
    right: 47%;
    transform: translate(140px, 55px);
  }
  .phonology-label {
    right: 43%;
    transform: translate(0px, 130px);
  }
  .interaction-label {
    right: 36%;
    transform: translate(-140px, 55px);
  }
  .coherence-label {
    right: 44%;
    transform: translate(-140px, -55px);
  }

  .intella-img-mobile-col {
    height: 300px;
  }
  .title-from-intella {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .message-from-intella {
    font-size: 0.9em;
    line-height: 22px;
  }

  /* モバイル表示用詳細 */
  .details-mobile {
    display: block;
  }
  .details-tab-item {
    height: 350px;
  }
  .detail-en-title {
    font-size: 1.4em;
  }
  .detail-cefr-p {
    margin-left: -40px;
  }
  .detail-description {
    margin-top: 25px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 375px) {
  /* Mobile M */
  .bar-card {
    height: 114px;
    padding: 3px 24px 0px 1px;
  }
  .bar-container {
    padding-left: 0px;
  }
  .bar-head-cefr {
    padding-left: 9px;
    padding-right: 9px;
  }
  .assess-title {
    margin-right: 25px;
  }
  .assess-res {
    font-size: 2.1em;
    margin-top: 5px;
  }
  .rader-label-name {
    font-size: 10px;
  }
  .rader-labels-li {
    top: 43%;
  }
  .range-label {
    right: 42%;
    transform: translate(0px, -144px);
  }
  .accuracy-label {
    right: 45%;
    transform: translate(140px, -60px);
  }
  .fluency-label {
    right: 50%;
    transform: translate(140px, 60px);
  }
  .phonology-label {
    right: 43%;
    transform: translate(0px, 130px);
  }
  .interaction-label {
    right: 33%;
    transform: translate(-140px, 60px);
  }
  .coherence-label {
    right: 42%;
    transform: translate(-140px, -60px);
  }
  .detail-cefr-p {
    margin-left: -20px;
  }
}
@media screen and (max-width: 320px) {
  /* Mobile S */
  .bar-card {
    height: 112px;
    padding: 3px 22px 0px 4px;
  }
  .bar-head-cefr-row {
    font-size: 13px;
  }
  .bar-head-cefr {
    padding-left: 8px;
    padding-right: 8px;
  }
  .overall-results {
    height: 80px;
  }
  .assess-title {
    margin-top: 17px;
    margin-right: 8px;
  }
  .assess-res {
    margin-top: 3px;
  }
  .rader-labels-li {
    top: 42.5%;
  }
  .range-label {
    right: 40%;
    transform: translate(0px, -138px);
  }
  .accuracy-label {
    right: 51%;
    transform: translate(140px, -85px);
  }
  .fluency-label {
    right: 56%;
    transform: translate(140px, 85px);
  }
  .phonology-label {
    right: 41.5%;
    transform: translate(0px, 132px);
  }
  .interaction-label {
    right: 24%;
    transform: translate(-140px, 85px);
  }
  .coherence-label {
    right: 33%;
    transform: translate(-140px, -85px);
  }
  .detail-cefr-p {
    margin-left: 5px;
  }
  .intella-img-mobile-col {
    height: 250px;
  }
}
</style>
