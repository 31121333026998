import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '@/views/Top.vue'
import Main from '@/views/Main.vue'
import Home from '@/views/Home.vue'
import SkillCheck from '@/views/SkillCheck.vue'
import Interview from '@/views/Interview.vue'
import FastAssessmentResults from '@/views/FastAssessmentResults.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Main,
    meta: {
      title: 'Intella',
      desc: '',
    },
    children: [
      {
        path: '',
        name: 'Top',
        component: Top,
      },
      {
        path: 'home',
        name: 'HOME',
        component: Home,
      },
    ],
  },
  {
    path: '/interview/:id',
    name: 'Interview',
    component: Interview,
    meta: {
      title: 'INTERVIEW',
      desc: '',
    },
  },
  {
    path: '/results/:id',
    name: 'Result',
    component: FastAssessmentResults,
    meta: {
      title: 'RESULT',
      desc: '',
    },
  },
  {
    path: '/skillcheck/:id',
    name: 'SkillCheck',
    component: SkillCheck,
    meta: {
      title: 'SKILL CHECK',
      desc: '',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
