<template class="full-screen">
  <div>
    <v-card flat color="#8e96b9" class="full-height-width-card ma-0 pa-0">
      <v-card
        color="transparent"
        flat
        class="top-background full-height-width-card ma-0 pa-0 d-flex align-end"
      />
      <v-card
        color="transparent"
        flat
        class="full-height-width-card overlay-card ma-0 pa-0 d-flex align-center justify-center"
      >
        <v-card flat class="top-card">
          <v-card-text class="mb-4 text-center white--text">
            <h1 class="font-weight-light top-title">Whole New Experience of</h1>
            <h1 class="font-weight-light top-title">Language Learning with</h1>
            <h1 class="font-weight-light top-title">Conversational AI</h1>
          </v-card-text>
          <v-card-actions class="d-flex align-center justify-center">
            <v-btn
              class="pink white--text top-button d-flex align-center"
              x-large
              @click="goToHome"
            >
              <p class="font-weight-regular top-button-text">
                <span class="top-button-meet">Meet</span> InteLLA
              </p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>
    <v-card
      flat
      color="white"
      class="full-width-card d-flex justify-center flex-wrap pt-10 pb-10"
    >
      <v-card flat color="transparent" class="ma-3 description-card">
        <v-card-title
          class="justify-center font-weight-black description-title"
        >
          INTERACTIVE
        </v-card-title>
        <v-card-subtitle class="text-center description-text">
          インタラクティブな会話
        </v-card-subtitle>
        <v-img
          class="description-icon"
          width="200"
          src="@/assets/top-interactive-icon.png"
        />
        <v-card-text class="description-text">
          インタラクティブな状況でスピーキング能力判定できる
        </v-card-text>
      </v-card>
      <v-card flat color="transparent" class="ma-3 description-card">
        <v-card-title
          class="justify-center font-weight-black description-title"
        >
          DIAGNOSTIC
        </v-card-title>
        <v-card-subtitle class="text-center description-text">
          診断的な能力判定
        </v-card-subtitle>
        <v-img
          class="description-icon"
          width="200"
          src="@/assets/top-diagnostic-icon.png"
        />
        <v-card-text class="description-text">
          現状分析と学習提案を同時に提供できる
        </v-card-text>
      </v-card>
      <v-card flat color="transparent" class="ma-3 description-card">
        <v-card-title
          class="justify-center font-weight-black description-title"
        >
          SELF-GUIDED
        </v-card-title>
        <v-card-subtitle class="text-center description-text">
          継続的な自己学習
        </v-card-subtitle>
        <v-img
          class="description-icon"
          width="200"
          src="@/assets/top-self-guided-icon.png"
        />
        <v-card-text class="description-text">
          一口サイズで自分のペースで英会話を学べる
        </v-card-text>
      </v-card>
    </v-card>
    <v-card
      flat
      color="#d5d5d5"
      class="full-width-card d-flex justify-center flex-wrap py-10 px-5"
    >
      <img class="ma-2 nomination-image" src="@/assets/rea-nomination.png" />
      <v-card flat color="transparent" class="ma-2 pa-2 nomination-card">
        <v-card-subtitle class="ma-0 pa-0 nomination-text">
          [部門賞ノミネーション]
        </v-card-subtitle>
        <v-card-title class="font-weight-bold ma-0 pa-0 nomination-title">
          InteLLA: Intelligent Language Learning Assistant for Automated
          Diagnostic Speaking Assessment
        </v-card-title>
        <v-card-text class="ma-0 pa-0 nomination-text">
          Reimagine Education Award 2021<br />
          Learning Assessment Category, Oct. 2021.
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Top',
  methods: {
    goToHome() {
      this.$router.push('/home')
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@700&family=Praise&display=swap');

.top-title {
  font-size: 40px;
  margin-bottom: 30px;
}

.top-button {
  width: 160px;
  height: 20px;
  line-height: 20px;
}

.top-button-text {
  font-size: 20px;
  margin: 0;
}

.top-button-meet {
  font-family: 'Praise', cursive;
}

.top-background {
  background-image: url('@/assets/intella-top.png');
  background-position: bottom 0px left 20px;
  background-repeat: no-repeat;
  background-size: auto 80vh;
}

.full-screen {
  height: 100vh; /* 全画面表示 */
  width: 100vw;
  padding: 0;
  margin: 0;
}

.full-height-width-card {
  height: 100vh;
  width: 100vw;
}

.full-width-card {
  width: 100vw;
  margin: 0;
}

.overlay-card {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.top-card {
  background-color: rgba(142, 150, 185, 0.6) !important;
}

.description-card {
  width: 300px;
}

.description-title {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 40px;
}

.description-text {
  font-size: 15px;
}

.description-icon {
  margin-left: 15px;
  margin-right: 15px;
}

.nomination-image {
  width: 350px;
}

.nomination-card {
  max-width: 600px;
}

.nomination-title {
  word-break: break-word;
  font-size: 25px;
}

.nomination-text {
  word-break: break-word;
  font-size: 15px;
}
</style>
