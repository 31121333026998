<template>
  <v-app-bar fixed elevate-on-scroll color="#8e96b9">
    <v-row class="align-left" justify="space-between">
      <span>
        <v-row class="align-center" justify="space-between">
          <v-img src="@/assets/intella-logo.png" class="header-logo ml-4" />
          <h2
            v-if="!$vuetify.breakpoint.xsOnly"
            class="white--text ml-2 font-weight-light"
          >
            |
          </h2>
          <h3
            v-if="!$vuetify.breakpoint.xsOnly"
            class="white--text ml-2 font-weight-light"
          >
            Intelligent Language Learning Assistant
          </h3>
        </v-row>
      </span>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>
<style scoped>
.rounded-btn {
  border-radius: 15px;
}
.return {
  transform: scaleX(-1);
}

.header-logo {
  max-width: 160px;
}
</style>
