<template>
  <v-app>
    <transition mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  created() {},
  mounted: function() {
    const routeInstance = this.$route
    this.createTitleDesc(routeInstance)
  },
  methods: {
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        const setTitle = routeInstance.meta.title + ' | InteLLA'
        document.title = setTitle
      } else {
        document.title = 'InteLLA'
      }
      // メタタグdescription設定
      if (routeInstance.meta.desc) {
        const setDesc = routeInstance.meta.desc + ' | InteLLA'
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', setDesc)
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', 'description is not set')
      }
    },
  },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.grad-red {
  background: linear-gradient(45deg, #f36258, #b80e65);
}
.grad-blue {
  background: linear-gradient(45deg, #8125f5, #1791b1);
}

.bg-gray {
  background-color: #8e96b9;
}

#app {
  font-family: 'Helvetica Neue', 'Helvetica', 'Hiragino Sans',
    'Hiragino Kaku Gothic ProN', 'Arial', 'Yu Gothic', 'Meiryo', sans-serif;
}

.test--row {
  height: 200px;
}

.sound--bar {
  width: 10px;
  height: 30px;
  border-radius: 2px;
  background-color: #312f30;
}

.sound--bar--light {
  background-color: #f9f9f9;
  width: 8px;
  height: 30px;
  border-radius: 2px;
}
.v-progress-circular__underlay {
  opacity: 0 !important;
  display: none !important;
}

.pink {
  background-color: #d41876;
}

.teal {
  background-color: #1791b1;
}
</style>
