<template>
  <v-app class="bg">
    <v-main class="bg">
      <v-stepper
        v-model="stepperId"
        fill-height
        flat
        class="transparent"
        alt-labels
      >
        <v-stepper-header class="stepper--header mt-9 mx-9 pa-0">
          <v-stepper-step
            :complete="stepperId > 1"
            step="1"
            color="#1791b1"
            class="text-caption ma-0"
          >
            注意事項
          </v-stepper-step>
          <v-divider />
          <template v-for="(oneTask, index) in allTasks">
            <v-stepper-step
              :key="`${index}-step`"
              :step="index + 2"
              :complete="stepperId > index + 2"
              color="#1791b1"
              class="text-caption ma-0"
            >
              {{ oneTask.taskTitle }}
            </v-stepper-step>
            <v-divider
              v-if="index !== allTasks.length"
              :key="`${index}-divider`"
            />
          </template>
          <v-stepper-step
            :step="allTasks.length + 2"
            color="#1791b1"
            class="text-caption ma-0"
          >
            保存して完了
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items id="skillCheckStepperItems" class="px-4" flat>
          <v-stepper-content step="1">
            <v-card color="transparent" class="ma-4" flat>
              <v-container>
                <v-card
                  class="title-bar white--text px-5 mb-5"
                  dark
                  width="100%"
                >
                  <v-container>
                    <v-row>
                      <v-col>
                        <h3>注意事項</h3>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card class="px-5 mb-5" light width="100%">
                  <v-container>
                    <v-row>
                      <v-col>
                        <strong>
                          下の注意事項をよくお読みください。
                        </strong>
                        <v-card outlined flat class="ma-10 pa-5">
                          <ul>
                            <li>
                              <p>
                                このスキルチェックでは、音声を録音するタスクがあります。周囲がうるさくない静かな場所で、必ずイヤホンマイクをご利用の上取り組んでください。
                              </p>
                            </li>
                            <li>
                              <p>
                                このスキルチェックは、あなたがこれから受講する英会話授業のクラス分けのために参考となるデータです。各課題の説明文をよく読み、自分の最大限の実力が発揮できるようにご回答ください。
                              </p>
                            </li>
                          </ul>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card class="px-5 mb-5" light width="100%">
                  <v-container>
                    <v-row>
                      <v-col>
                        <strong>
                          イヤホンマイク等ヘッドセットをご活用の上、マイク入力を選び、マイクテストをしてください。
                        </strong>
                      </v-col>
                    </v-row>
                    <v-row class="test--row">
                      <v-col
                        id="microphoneCheckIndicator"
                        :cols="6"
                        class="d-flex flex-wrap align-content-center justify-space-around px-15"
                      >
                        <v-icon class="mx-5">
                          {{
                            selectedMicrophoneId
                              ? 'mdi-microphone'
                              : 'mdi-microphone-off'
                          }}
                        </v-icon>
                        <div
                          v-for="n in parseInt(25)"
                          :key="n"
                          class="sound--bar--light"
                        />
                      </v-col>
                      <v-col
                        :cols="6"
                        class="d-flex flex-wrap align-content-center justify-center"
                      >
                        <v-select
                          v-model="selectedMicrophoneId"
                          :items="microphones"
                          item-text="label"
                          item-value="id"
                          :rules="[v => !!v || 'マイクが必要です']"
                          label="マイクを選ぶ"
                          required
                          @change="activateMicrophoneTestIndicator()"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card
                  class="d-flex justify-end"
                  width="100%"
                  flat
                  color="transparent"
                >
                  <v-btn
                    class="pink-red white--text"
                    :disabled="!isReadyToNextTask"
                    @click="goToNextTask"
                  >
                    次へ
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card>
              </v-container>
            </v-card>
          </v-stepper-content>
          <v-stepper-content
            v-for="(oneTask, index) in allTasks"
            :key="index"
            :step="index + 2"
          >
            <v-card color="transparent" class="ma-4" flat>
              <v-container>
                <v-card
                  class="title-bar white--text px-5 mb-5"
                  dark
                  width="100%"
                >
                  <v-container>
                    <v-row>
                      <v-col>
                        <h3>{{ oneTask.taskTitle }}</h3>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card
                  v-if="oneTask.taskType === 'yes-no'"
                  class="px-5 mb-5"
                  light
                  width="100%"
                >
                  <v-container>
                    <v-row>
                      <v-col>
                        <strong>
                          あなたの英会話の能力を自己評価して、下の各文章にあてはまるなら「YES」、あてはまらないなら「NO」と答えてください。
                        </strong>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card
                  v-if="oneTask.taskType === 'yes-no'"
                  class="pa-5 mb-5"
                  light
                  width="100%"
                >
                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr
                          v-for="(oneText, textIndex) in oneTask.taskContent"
                          :key="textIndex"
                        >
                          <td>
                            {{ oneText }}
                          </td>
                          <td class="d-flex justify-center align-center">
                            <v-radio-group
                              v-model="
                                radioResponses[
                                  (textIndex + 1).toString().padStart(3, '0')
                                ]
                              "
                              row
                              @change="
                                isReadyToNextTask =
                                  Object.keys(radioResponses).length ===
                                  oneTask.taskContent.length
                              "
                            >
                              <v-radio
                                label="YES"
                                value="yes"
                                color="#1791b1"
                              />
                              <v-radio label="NO" value="no" color="#1791b1" />
                            </v-radio-group>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
                <v-card
                  v-if="oneTask.taskType === 'audio'"
                  class="mb-5"
                  light
                  width="100%"
                >
                  <v-container class="pa-0">
                    <v-row class="mx-5 mb-5">
                      <v-col>
                        <strong>
                          準備時間30秒間の後に録音が始まります。録音が開始したら、下の文章をできるだけ大きい声ではっきりと読み上げてください。読み上げが終わったら下のマイクアイコンをクリックして、録音を停止させてください。
                        </strong>
                        <v-card outlined flat class="ma-10 pa-5">
                          <p class="pre-wrap-text text-body-1">
                            {{ oneTask.taskContent.replaceAll('\\n', '\n') }}
                          </p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col class="pa-0 ma-0">
                        <div class="audio-input-background rounded-b pa-0 ma-0">
                          <div
                            class="audio-input-bar rounded-b pa-0 ma-0 d-flex justify-center align-center"
                          >
                            <div class="audio-input-time">
                              <i
                                id="audioInputIcon"
                                class="mdi mdi-checkbox-blank-circle-outline white--text"
                              />
                              <span id="audioInputTime" class="white--text">
                                00:00
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="audio-indicator-background gray-background rounded-circle pa-0"
                        >
                          <div
                            class="audio-indicator black-background rounded-circle pa-0"
                          >
                            <img
                              class="audio-indicator-icon pa-0"
                              src="@/assets/skillcheck/icon-finished.svg"
                            />
                          </div>
                          <div
                            id="audioSavingLoader"
                            class="audio-indicator black-background rounded-circle pa-0"
                          >
                            <v-progress-circular
                              indeterminate
                              :size="100"
                              :width="10"
                              color="#b80e65"
                            />
                          </div>
                          <div
                            id="audioIndicator"
                            class="audio-indicator audio-indicator-btn black-background rounded-circle pa-0"
                            @click="saveAudio()"
                          >
                            <div
                              class="audio-indicator-microphone-background white-background pa-0"
                            />
                            <div
                              id="audioIndicatorLevel"
                              class="audio-indicator-level grad-red pa-0"
                            />
                            <img
                              class="audio-indicator-icon audio-indicator-btn pa-0"
                              src="@/assets/skillcheck/icon-microphone.svg"
                            />
                          </div>
                          <div
                            id="audioIndicatorIcon1"
                            class="audio-indicator black-background rounded-circle pa-0"
                          >
                            <img
                              class="audio-indicator-icon pa-0"
                              src="@/assets/skillcheck/icon-count-1.svg"
                            />
                          </div>
                          <div
                            id="audioIndicatorIcon2"
                            class="audio-indicator black-background rounded-circle pa-0"
                          >
                            <img
                              class="audio-indicator-icon pa-0"
                              src="@/assets/skillcheck/icon-count-2.svg"
                            />
                          </div>
                          <div
                            id="audioIndicatorIcon3"
                            class="audio-indicator black-background rounded-circle pa-0"
                          >
                            <img
                              class="audio-indicator-icon pa-0"
                              src="@/assets/skillcheck/icon-count-3.svg"
                            />
                          </div>
                          <div
                            id="preparationTimer"
                            class="audio-indicator black-background rounded-circle pa-0 text-center"
                          >
                            <v-progress-circular
                              :rotate="-90"
                              :size="100"
                              :width="10"
                              :value="
                                (audioRecordingPreparationRemainingTime / 30) *
                                  100
                              "
                              color="#1791b1"
                            >
                              <h2 class="ma-0 pa-0 white--text">
                                {{ audioRecordingPreparationRemainingTime }}
                              </h2>
                            </v-progress-circular>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-card
                  class="d-flex justify-end"
                  width="100%"
                  flat
                  color="transparent"
                >
                  <v-btn
                    class="pink-red white--text"
                    :disabled="!isReadyToNextTask"
                    @click="goToNextTask"
                  >
                    次へ
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card>
              </v-container>
            </v-card>
          </v-stepper-content>
          <v-stepper-content :step="allTasks.length + 2">
            <v-card
              class="d-flex flex-column justify-center align-center text-center ma-4"
              height="400px"
              color="transparent"
              flat
            >
              <strong class="mt-16">
                以上でスキルチェックは終了です。下のボタンを押して、スキルチェックを完了してください。
              </strong>
              <v-btn
                dark
                x-large
                class="ma-16 grad-red"
                :loading="submitAllResponsesLoading"
                :disabled="submitAllResponsesLoading"
                @click="submitAllResponses()"
              >
                <v-icon>
                  mdi-check
                </v-icon>
                保存してスキルチェックを完了
              </v-btn>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-main>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="pageLoadingOverlay">
      <v-progress-circular
        indeterminate
        color="#1791b1"
        size="100"
        width="10"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import { database, storage } from '@/plugins/firebase'

export default {
  name: 'SkillCheck',
  data: function() {
    return {
      pageLoadingOverlay: true,
      skillCheckStartedAt: null,
      model: [],
      stepperId: 1,
      allTasks: [],
      currentTaskId: null,
      microphones: [],
      selectedMicrophoneId: null,
      activeStepper: null,
      isReadyToNextTask: false,
      taskStartedAt: null,
      taskSelectedAt: [],
      radioResponses: {},
      textResponse: null,
      audioResponse: null,
      resultDataset: [],
      audioContext: null,
      audioData: [],
      audioSampleRate: null,
      bufferSize: 1024,
      audioTestIndicatorTimer: null,
      audioIndicatorTimer: null,
      audioRecordingTimer: null,
      audioRecordingElapsedTime: 0,
      audioRecordingTimelimit: 90,
      audioRecordingPreparationTimelimit: 30,
      audioRecordingPreparationRemainingTime: null,
      audioRecordingPreparationTimer: null,
      errorMessage: '',
      errorSnackbar: false,
      submitAllResponsesLoading: false,
    }
  },
  computed: {},
  mounted() {
    this.skillCheckStartedAt = Date.now()
    database.ref('customersList').once('value', snapshot => {
      const customers = snapshot.val()
      const customerIds = Object.keys(customers)
      if (customerIds.includes(this.$route.params.id)) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            navigator.mediaDevices.enumerateDevices().then(allMediaDevices => {
              this.microphones = allMediaDevices.filter(
                oneMediaDevice => oneMediaDevice.kind === 'audioinput'
              )
              database.ref('skillCheckTasks').once('value', snapshot => {
                const tasks = snapshot.val()
                for (const task in tasks) {
                  this.allTasks.push(tasks[task])
                  this.pageLoadingOverlay = false
                }
              })
            })
          })
          .catch(() => {
            this.errorMessage =
              'ブラウザの設定でこのサイトのマイクへのアクセスを許可してください。'
            this.errorSnackbar = true
          })
      } else {
        this.errorMessage = 'コードが正しくありません．'
        this.errorSnackbar = true
      }
    })
  },
  destroyed() {
    this.$returnScroll()
  },
  methods: {
    submitAllResponses() {
      this.submitAllResponsesLoading = true
      this.saveResultCSVInStorage()
    },
    getSum(dataArray) {
      return dataArray.reduce((prev, current) => prev + current, 0)
    },
    getMean(dataArray) {
      return this.getSum(dataArray) / dataArray.length
    },
    goToNextTask() {
      // stepperIdは1から始まり、1は解説なので、2のときにスキルチェックの0番が入るように指定
      if (this.audioIndicatorTestTimer) {
        clearInterval(this.audioIndicatorTestTimer)
      }
      if (Object.keys(this.radioResponses).length > 0) {
        for (const oneResponseId in this.radioResponses) {
          this.addResultIntoDataset(
            `${this.currentTaskId}-${oneResponseId}`,
            this.radioResponses[oneResponseId]
          )
        }
        this.radioResponses = {}
      }
      if (this.textResponse) {
        this.addResultIntoDataset(this.currentTaskId, this.textResponse)
        this.textResponse = null
      }
      if (this.audioResponse) {
        this.addResultIntoDataset(this.currentTaskId, this.audioResponse)
        this.audioResponse = null
        this.audioRecordingPreparationTimer = null
        this.audioRecordingPreparationRemainingTime = null
      }
      this.isReadyToNextTask = false
      this.stepperId++
      this.currentTaskId =
        this.stepperId - 2 < 0 || this.stepperId > this.allTasks.length + 1
          ? null
          : this.allTasks[this.stepperId - 2].taskId
      this.activeStepper = document.getElementById(
        'skillCheckStepperItems'
      ).children[this.stepperId - 1]
      if (
        this.stepperId - 2 < 0 || this.stepperId > this.allTasks.length + 1
          ? null
          : this.allTasks[this.stepperId - 2].taskType === 'audio'
      ) {
        this.audioRecordingPreparationRemainingTime = this.audioRecordingPreparationTimelimit
        this.startPreparationCountDown()
      }
      this.taskStartedAt = Date.now()
    },
    startPreparationCountDown() {
      this.audioRecordingPreparationTimer = setInterval(() => {
        this.audioRecordingPreparationRemainingTime -= 1
        if (this.audioRecordingPreparationRemainingTime === 3) {
          clearInterval(this.audioRecordingPreparationTimer)
          this.activeStepper.querySelector('#preparationTimer').remove()
          this.startThreeCountDown()
        }
      }, 1000)
    },
    startThreeCountDown() {
      let countDownTime = 3
      const threeCountDownTimer = setInterval(() => {
        this.activeStepper
          .querySelector(`#audioIndicatorIcon${countDownTime}`)
          .remove()
        countDownTime -= 1
        if (countDownTime === 0) {
          clearInterval(threeCountDownTimer)
          this.startRecording()
        }
      }, 1000)
    },
    activateMicrophoneTestIndicator() {
      if (this.audioIndicatorTestTimer) {
        clearInterval(this.audioIndicatorTestTimer)
      }
      if (this.audioContext) {
        this.audioContext = null
      }
      const microphoneCheckIndicator = document.getElementById(
        'microphoneCheckIndicator'
      )
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: this.selectedMicrophoneId } })
        .then(stream => {
          const audioAnalyser = this.audioContext.createAnalyser()
          const audioFrequencies = new Uint8Array(
            audioAnalyser.frequencyBinCount
          )
          this.audioContext
            .createMediaStreamSource(stream)
            .connect(audioAnalyser)
          this.audioIndicatorTestTimer = setInterval(() => {
            audioAnalyser.getByteFrequencyData(audioFrequencies)
            const audioVolume = this.getMean(audioFrequencies.slice(20, -20))
            this.showAudioTestIndicator(microphoneCheckIndicator, audioVolume)
          }, 20)
          if (this.selectedMicrophoneId) {
            this.isReadyToNextTask = true
          }
        })
    },
    startRecording() {
      this.audioInputIcon = this.activeStepper.querySelector('#audioInputIcon')
      if (!this.audioIndicatorTimer) {
        const audioIndicatorLevel = this.activeStepper.querySelector(
          '#audioIndicatorLevel'
        )
        const audioInputTime = this.activeStepper.querySelector(
          '#audioInputTime'
        )
        this.audioInputIcon.className =
          'mdi mdi-checkbox-blank-circle audio-input-icon audio-blink-recording pink-red-icon'
        audioInputTime.textContent = this.getMinutesSecondsString(
          this.audioRecordingElapsedTime
        )
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)()
        this.audioSampleRate = this.audioContext.sampleRate
        this.scriptProcessor = this.audioContext.createScriptProcessor(
          this.bufferSize,
          1,
          1
        )
        navigator.mediaDevices
          .getUserMedia({ audio: { deviceId: this.selectedMicrophoneId } })
          .then(stream => {
            this.audioContext
              .createMediaStreamSource(stream)
              .connect(this.scriptProcessor)
            this.scriptProcessor.onaudioprocess = this.onAudioProcess
            this.scriptProcessor.connect(this.audioContext.destination)
            const audioAnalyser = this.audioContext.createAnalyser()
            const audioFrequencies = new Uint8Array(
              audioAnalyser.frequencyBinCount
            )
            this.audioContext
              .createMediaStreamSource(stream)
              .connect(audioAnalyser)
            this.audioIndicatorTimer = setInterval(() => {
              audioAnalyser.getByteFrequencyData(audioFrequencies)
              const audioVolume = this.getMean(audioFrequencies.slice(20, -20))
              this.showAudioRecordingIndicator(audioIndicatorLevel, audioVolume)
            }, 20)
            this.audioRecordingTimer = setInterval(() => {
              if (
                this.audioRecordingElapsedTime === this.audioRecordingTimelimit
              ) {
                this.saveAudio()
              } else {
                this.audioRecordingElapsedTime += 1
                this.audioInputIcon.className =
                  'mdi mdi-checkbox-blank-circle audio-blink-recording pink-red-icon'
                audioInputTime.textContent = this.getMinutesSecondsString(
                  this.audioRecordingElapsedTime
                )
              }
            }, 1000)
          })
      } else {
        this.saveAudio()
      }
    },
    saveAudio() {
      this.audioInputIcon.className =
        'mdi mdi-checkbox-blank-circle white--text'
      this.audioInputIcon = null
      this.activeStepper.querySelector('#audioIndicator').remove()
      clearInterval(this.audioIndicatorTimer)
      this.audioIndicatorTimer = null
      clearInterval(this.audioRecordingTimer)
      this.audioRecordingTimer = null
      this.audioRecordingElapsedTime = 0
      this.audioContext.close()
      const audioBlob = this.exportWav()
      storage
        .child(
          `skillCheckRecords/${this.$route.params.id}/${this.skillCheckStartedAt}/${this.currentTaskId}.wav`
        )
        .put(audioBlob)
        .then(() => {
          this.audioSampleRate = null
          this.scriptProcessor = null
          this.audioData = []
          this.audioResponse = this.currentTaskId + '.wav'
          this.isReadyToNextTask = true
          this.activeStepper.querySelector('#audioSavingLoader').remove()
        })
    },
    writeString(view, offset, strings) {
      for (let i = 0; i < strings.length; i++) {
        view.setUint8(offset + i, strings.charCodeAt(i))
      }
    },
    floatTo16BitPCM(output, offset, input) {
      for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]))
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
      }
    },
    encodeWav(samples, sampleRate) {
      const buffer = new ArrayBuffer(44 + samples.length * 2)
      const view = new DataView(buffer)
      this.writeString(view, 0, 'RIFF')
      view.setUint32(4, 32 + samples.length * 2, true)
      this.writeString(view, 8, 'WAVE')
      this.writeString(view, 12, 'fmt ')
      view.setUint32(16, 16, true)
      view.setUint16(20, 1, true)
      view.setUint16(22, 1, true)
      view.setUint32(24, sampleRate, true)
      view.setUint32(28, sampleRate * 2, true)
      view.setUint16(32, 2, true)
      view.setUint16(34, 16, true)
      this.writeString(view, 36, 'data')
      view.setUint32(40, samples.length * 2, true)
      this.floatTo16BitPCM(view, 44, samples)
      return view
    },
    mergeBuffers() {
      let sampleLength = 0
      for (let i = 0; i < this.audioData.length; i++) {
        sampleLength += this.audioData[i].length
      }
      const samples = new Float32Array(sampleLength)
      let sampleIdx = 0
      for (let i = 0; i < this.audioData.length; i++) {
        for (let j = 0; j < this.audioData[i].length; j++) {
          samples[sampleIdx] = this.audioData[i][j]
          sampleIdx++
        }
      }
      return samples
    },
    exportWav() {
      const dataview = this.encodeWav(this.mergeBuffers(), this.audioSampleRate)
      const audioBlob = new Blob([dataview], { type: 'audio/wav' })
      return audioBlob
    },
    onAudioProcess(e) {
      const input = e.inputBuffer.getChannelData(0)
      const bufferData = new Float32Array(this.bufferSize)
      for (let i = 0; i < this.bufferSize; i++) {
        bufferData[i] = input[i]
      }
      this.audioData.push(bufferData)
    },
    saveResultCSVInStorage() {
      const header = [
        [
          'userId',
          'langxId',
          'taskId',
          'startedAt',
          'submittedAt',
          'responseTime',
          'responseContent',
        ],
      ]
      const datasetArray = header.concat(this.resultDataset)
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const csvData = datasetArray.map(oneRow => oneRow.join(',')).join('\r\n')
      const blobData = new Blob([bom, csvData], { type: 'text/csv' })
      storage
        .child(
          `skillCheckRecords/${this.$route.params.id}/${this.skillCheckStartedAt}/results.csv`
        )
        .put(blobData)
        .then(() => {
          window.close()
        })
    },
    addResultIntoDataset(taskId, responseContent) {
      const submittedAt = Date.now()
      this.resultDataset.push([
        this.$route.params.id,
        this.$route.params.id,
        taskId,
        this.taskStartedAt,
        submittedAt,
        submittedAt - this.taskStartedAt,
        responseContent,
      ])
    },
    showAudioTestIndicator(indicator, volume) {
      const soundVolume = Math.floor(25 * (volume / 60))
      const soundLevel = soundVolume > 25 ? 25 : soundVolume
      let level = 0
      for (level; level < soundLevel; level++) {
        indicator.children[level + 1].style.backgroundColor = '#b80e65'
      }
      for (level; level < 25; level++) {
        indicator.children[level + 1].style.backgroundColor = '#f9f9f9'
      }
    },
    showAudioRecordingIndicator(indicator, volume) {
      const indicatorHeight = 39 * (volume / 60)
      indicator.style.height =
        indicatorHeight > 39 ? 39 + 'px' : indicatorHeight + 'px'
    },
    getMinutesSecondsString(timeRemainingInSeconds) {
      const minutes = (timeRemainingInSeconds / 60) | 0
      const seconds = timeRemainingInSeconds % 60
      return ` ${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')} `
    },
  },
}
</script>

<style scoped>
.bg {
  height: 100vh; /* 全画面表示 */
  background-color: #f9f9f9;
}

.title-bar {
  background-color: #1791b1;
  color: #ffffff;
}
.transparent {
  background-color: transparent !important;
}
.pink-red {
  background-color: #b80e65 !important;
}

.audio-input-background {
  height: 100px;
  width: 100%;
}

.audio-input-bar {
  position: absolute;
  bottom: 0;
  background-color: #312f30 !important;
  height: 70px;
  width: 100%;
}

.audio-input-time {
  border-radius: 5px;
  background-color: #000000;
  padding: 0 10px 0;
  margin-left: 250px;
}

.audio-input-icon {
  opacity: 0%;
}

.audio-indicator-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  height: 120px;
  width: 120px;
}

.audio-indicator {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  height: 100px;
  width: 100px;
}

.audio-indicator-btn {
  cursor: pointer;
}

.audio-indicator-microphone-background {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  height: 60px;
  width: 40px;
}

.audio-indicator-icon {
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  height: 64px;
  width: 64px;
}

.audio-indicator-level {
  position: absolute;
  bottom: 41px;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  height: 0px;
  width: 40px;
}

.gray-background {
  background-color: #312f30 !important;
}

.black-background {
  background-color: #000000 !important;
}

.white-background {
  background-color: #ffffff !important;
}

.pink-red-icon {
  color: #b80e65;
}

.audio-blink-recording {
  animation: blinking 1s ease-in-out none infinite alternate-reverse;
}

@keyframes blinking {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.stepper--header {
  background-color: #ffffff !important;
}
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
