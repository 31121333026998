<template #header>
  <div>
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
    <v-footer padless color="#8e96b9">
      <v-col class="text-center text-caption white--text" cols="12">
        &#169; {{ new Date().getFullYear() }} Equmenopolis.
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'Main',
  components: {
    Navbar,
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {},
  watch: {
    $route: function(to, from) {
      this.name = this.$route.name
    },
  },
  mounted() {
    this.name = this.$route.name
  },
  methods: {},
}
</script>
<style scoped></style>
