import App from '@/App.vue'
import Vue from 'vue'
import Vuex from 'vuex'
import VueClipboard from 'vue-clipboard2'

// npmパッケージの読み込み
import VuePapaParse from 'vue-papa-parse'
import VCalendar from 'v-calendar'
import firebase from 'firebase'
import { firestorePlugin } from 'vuefire'
import moment from 'moment'

// UIコンポーネントの読み込み
import vuetify from './plugins/vuetify'
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' // material-icons
import 'vuesax/dist/vuesax.css' // Vuesax

// pluginの読み込み
import router from '@/router'

// 開発中は true
// Vue.config.productionTip = true
Vue.config.productionTip = false

Vue.use(VuePapaParse)
Vue.use(Vuesax, {})
Vue.use(VCalendar, {
  componentPrefix: 'vc',
})
Vue.use(firestorePlugin)
Vue.use(moment)
Vue.use(Vuex)
Vue.use(VueClipboard)

new Vue({
  vuetify,
  router,
  firebase,
  render: h => h(App),
}).$mount('#app')
