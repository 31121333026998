<template>
  <v-app>
    <v-main class="bg">
      <div id="videoContainer" class="video-container ma-0 pa-0" />
    </v-main>
    <v-dialog v-model="checkAudioVideoDialog" persistent dark flat width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          マイク・カメラテスト
        </v-card-title>
        <v-container>
          <v-row class="test--row">
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <div id="cameraCheckWindow" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedCameraId"
                :items="cameras"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || 'カメラが必要です']"
                label="カメラを選ぶ"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
          <v-row class="test--row">
            <v-col
              id="microphoneCheckWindow"
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-space-around"
            >
              <v-icon color="#ffffff">
                {{
                  selectedMicrophoneId ? 'mdi-microphone' : 'mdi-microphone-off'
                }}
              </v-icon>
              <div v-for="n in parseInt(10)" :key="n" class="sound--bar" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedMicrophoneId"
                :items="microphones"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || 'マイクが必要です']"
                label="マイクを選ぶ"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="joinMeetingRoom()">
            入室する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="pageLoadingOverlay">
      <v-progress-circular
        indeterminate
        color="#1791b1"
        size="100"
        width="10"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import { database, functions } from '@/plugins/firebase'

export default {
  name: 'Interview',
  data: () => {
    return {
      pageLoadingOverlay: true,
      checkAudioVideoDialog: false,
      selectedCameraId: null,
      cameras: [],
      localVideoTrack: null,
      selectedMicrophoneId: null,
      microphones: [],
      localAudioTrack: null,
      testAudioTimer: null,
      microphoneDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      cameraDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      localStreamId: 0,
      rtc: {
        localAudioTrack: null,
        localVideoTrack: null,
        client: null,
      },
      agentId: '',
      agentInfo: null,
      errorSnackbar: false,
      errorMessage: '',
      isInterviewedRef: null,
    }
  },
  mounted() {
    database.ref('customersList').once('value', snapshot => {
      const customers = snapshot.val()
      const customerIds = Object.keys(customers)
      if (customerIds.includes(this.$route.params.id)) {
        this.checkAudioVideoDialog = true
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {
            AgoraRTC.getDevices().then(devices => {
              this.microphones = devices.filter(device => {
                return device.kind === 'audioinput'
              })
              this.cameras = devices.filter(device => {
                return device.kind === 'videoinput'
              })
              this.pageLoadingOverlay = false
            })
          })
          .catch(() => {
            this.errorMessage =
              'ブラウザの設定でこのサイトのカメラとマイクへのアクセスを許可してください。'
            this.errorSnackbar = true
          })
      } else {
        this.errorMessage = 'コードが正しくありません．'
        this.errorSnackbar = true
      }
    })
  },
  beforeDestroy() {
    this.isInterviewedRef.off()
  },
  destroyed() {
    this.$returnScroll()
  },
  methods: {
    async createTestStream() {
      return Promise.all([
        AgoraRTC.createCameraVideoTrack({ cameraId: this.selectedCameraId }),
        AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: this.selectedMicrophoneId,
        }),
      ]).then(tracks => {
        this.localVideoTrack = tracks[0]
        this.localAudioTrack = tracks[1]
        if (document.getElementById('testVideo')) {
          document.getElementById('testVideo').remove()
        }
        if (this.selectedCameraId !== null) {
          this.localVideoTrack.setDevice(this.selectedCameraId).then(() => {
            const cameraCheckWindow = document.getElementById(
              'cameraCheckWindow'
            )
            const testVideoDiv = document.createElement('div')
            testVideoDiv.id = 'testVideo'
            testVideoDiv.style.transform = 'rotateY(180deg)'
            testVideoDiv.style.width = '200px'
            testVideoDiv.style.height = '150px'
            testVideoDiv.style.margin = '5px'
            cameraCheckWindow.appendChild(testVideoDiv)
            this.localVideoTrack.play('testVideo')
          })
        }
        if (this.selectedMicrophoneId !== null) {
          this.localAudioTrack.setDevice(this.selectedMicrophoneId).then(() => {
            if (this.testAudioTimer) {
              clearInterval(this.testAudioTimer)
            }
            this.testAudioTimer = setInterval(() => {
              const soundLevel = Math.floor(
                this.localAudioTrack.getVolumeLevel() * 20
              )
              const microphoneCheckWindow = document.getElementById(
                'microphoneCheckWindow'
              )
              let level = 0
              for (level; level < soundLevel; level++) {
                microphoneCheckWindow.children[
                  level + 1
                ].style.backgroundColor = '#b80e65'
              }
              for (level; level < 10; level++) {
                microphoneCheckWindow.children[
                  level + 1
                ].style.backgroundColor = '#312f30'
              }
            }, 100)
          })
        }
      })
    },
    async joinMeetingRoom() {
      if (this.selectedCameraId && this.selectedMicrophoneId) {
        // マイク・カメラテスト画面上のトラック・タイマーを停止、削除
        const testVideoDiv = document.getElementById('testVideo')
        testVideoDiv.remove()
        clearInterval(this.testAudioTimer)
        // マイク・カメラテスト画面を非表示
        this.checkAudioVideoDialog = false
        // ページオーバーレイ・ローダーを表示
        this.pageLoadingOverlay = true
        // AIエージェント情報を取得
        const checkAvailableAgent = await functions.httpsCallable(
          'checkAvailableAgent'
        )
        const agentInfo = await checkAvailableAgent({
          apiUrl: process.env.VUE_APP_INTELLA_MANAGER_URL,
          uid: 'demo',
        })
        this.agentInfo = agentInfo.data
        if (this.agentInfo.isAvailable) {
          this.pageLoadingOverlay = false
          this.rtc = {
            localAudioTrack: this.localAudioTrack,
            localVideoTrack: this.localVideoTrack,
            client: AgoraRTC.createClient({ mode: 'live', codec: 'h264' }),
          }
          this.joinMeeting(this.agentInfo.channelId)
        } else {
          this.errorMessage =
            '現在InteLLAはインタビュー中です。15分後に再びお試しください。'
          this.errorSnackbar = true
        }
      }
    },
    async joinMeeting(channelId) {
      this.rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
      this.rtc.client.on('user-published', async (user, mediaType) => {
        await this.rtc.client.subscribe(user, mediaType)
        if (mediaType === 'video') {
          const remoteVideoTrack = user.videoTrack
          const streamDiv = this.addVideoStream(user.uid)
          remoteVideoTrack.play(streamDiv)
        }
        if (mediaType === 'audio') {
          const remoteAudioTrack = user.audioTrack
          remoteAudioTrack.play()
        }
        this.rtc.client.on('user-unpublished', user => {
          const remotePlayerContainer = document.getElementById(user.uid)
          remotePlayerContainer.remove()
          this.rtc.client.leave()
          this.localVideoTrack.close()
          this.localAudioTrack.close()
          this.isInterviewedRef.off()
          this.$router.push(`/results/${this.agentInfo.channelId}`)
        })
      })
      await this.rtc.client.join(
        process.env.VUE_APP_AGORA_APP_ID,
        channelId,
        null,
        null
      )
      await this.rtc.client.publish([
        this.rtc.localAudioTrack,
        this.rtc.localVideoTrack,
      ])
      const videoContainer = document.getElementById('videoContainer')
      const localDiv = document.createElement('div')
      localDiv.id = 'localVideo'
      localDiv.style.transform = 'rotateY(180deg)'
      localDiv.style.width = '200px'
      localDiv.style.height = '150px'
      localDiv.style.position = 'absolute'
      localDiv.style.top = 0
      localDiv.style.right = 0
      localDiv.style.border = '2px solid #1791B1'
      localDiv.style.zIndex = 99
      localDiv.style.margin = '10px'
      videoContainer.appendChild(localDiv)
      this.rtc.localVideoTrack.play(localDiv)
      this.isInterviewedRef = database.ref(`results/${channelId}/isInterviewed`)
      this.isInterviewedRef.on('value', async snapshot => {
        const isInterviewed = snapshot.val()
        if (isInterviewed) {
          this.localVideoTrack.stop()
          this.localVideoTrack.close()
          this.localAudioTrack.stop()
          this.localAudioTrack.close()
          await this.rtc.client.leave()
          this.isInterviewedRef.off()
          this.$router.push(`/results/${this.agentInfo.channelId}`)
        }
      })
    },
    addVideoStream(elementId) {
      const videoContainer = document.getElementById('videoContainer')
      const streamDiv = document.createElement('div')
      streamDiv.id = elementId
      streamDiv.style.width = '100%'
      streamDiv.style.height = '100%'
      streamDiv.style.position = 'absolute'
      streamDiv.style.top = 0
      streamDiv.style.left = 0
      streamDiv.style.zIndex = 90
      videoContainer.appendChild(streamDiv)
      return streamDiv
    },
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh; /* 全画面表示 */
  background-color: #f4f4f4;
}

.video-container {
  width: 100%;
  height: 100%;
}
</style>
