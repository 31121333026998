<script>
import { Radar } from 'vue-chartjs'

export default {
  name: 'FastResultsRaderChart',
  extends: Radar,
  props: {
    fastSubcategoryScore: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartdata: {
        labels: [
          '表現の豊富さ',
          '文法的正しさ',
          '   流暢さ',
          '発音の良さ',
          'インタラクティブ性',
          '一貫性   ',
        ],
        datasets: [
          {
            label: '各カテゴリ得点',
            data: [
              this.fastSubcategoryScore.resultCefr.range.score,
              this.fastSubcategoryScore.resultCefr.accuracy.score,
              this.fastSubcategoryScore.resultCefr.fluency.score,
              this.fastSubcategoryScore.resultCefr.phonology.score,
              this.fastSubcategoryScore.resultCefr.interaction.score,
              this.fastSubcategoryScore.resultCefr.coherence.score,
            ],
            backgroundColor: 'rgba(209, 95, 149, 0.7)',
            borderColor: 'rgb(209, 95, 149)',
            pointHoverBackgroundColor: 'rgba(209, 95, 149, 0.7)',
            pointHoverBorderColor: 'rgb(209, 95, 149)',
            borderWidth: 1.5,
            pointRadius: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scale: {
          // 外側のラベル
          pointLabels: {
            display: false,
            fontSize: 13,
            fontFamily: 'PingFang HK',
            fontColor: '#292929',
            fontStyle: 'bold',
          },
          // 目盛
          ticks: {
            fontSize: 13,
            stepSize: 1.0,
            min: 0.0,
            max: 6.0,
            display: true,
            beginAtZero: true,
            fontColor: 'black',
            fontFamily: 'Roboto Condensed',
            fontStyle: 'bold',
            fontWidth: 1,
            backdropColor: '#f4f4f4',
            callback: function(value, index, values) {
              const ticks = ['1.0', '2.0', '3.0', '4.0', '5.0', '6.0']
              return ticks[index - 1]
            },
          },
          angleLines: {
            display: false,
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0.2)',
          },
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltips: {
          enabled: false,
        },
        animation: {
          easing: 'linear',
        },
      },
    }
  },
  mounted: function() {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
