<template>
  <v-card
    color="transparent"
    flat
    class="full-height-width-card d-flex justify-center flex-wrap"
  >
    <v-card
      color="transparent"
      flat
      class="d-flex flex-column align-center justify-center home-half-box"
    >
      <h3>サンプル</h3>
      <div
        style="box-shadow:0 1.9px 2.5px rgba(0, 0, 0, 0.057),0 5px 6.1px rgba(0, 0, 0, 0.076),0 10.1px 11.4px rgba(0, 0, 0, 0.086),0 19.2px 19.8px rgba(0, 0, 0, 0.092),0 38.4px 34.8px rgba(0, 0, 0, 0.1),0 101px 74px rgba(0, 0, 0, 0.13);height:400px;width:300px;background-color:white;border-radius:5px;margin:50px;padding:0;display:float;"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/intella-demo-app/o/staticFiles%2Fintella-video.gif?alt=media&token=005be5a5-7519-46df-b5a5-675f0c794df6"
          style="width:300px;height:250px;margin:0;border-radius:5px 5px 0 0;"
        />
        <div
          style="width:300px;height:150px;padding:0px;margin:0px;display:flex;flex-direction:column;align-items:center;justify-content:center;border-radius:0 0 5px 5px;"
        >
          <a
            href="javascript:void(0);"
            style="width:200px;height:40px;line-height:40px;display:flex;align-items:center;justify-content:center;color:white;background-color:#1791b1;border-radius:25px;text-decoration:none;margin-bottom:10px;"
            @click="openSkillCheckWindow"
          >
            <h3>Check your skill</h3>
          </a>
          <a
            href="javascript:void(0);"
            style="width:200px;height:40px;line-height:40px;display:flex;align-items:center;justify-content:center;color:white;background-color:#d41876;border-radius:25px;text-decoration:none;"
            @click="openInterviewWindow"
          >
            <h3>Meet InteLLA</h3>
          </a>
        </div>
      </div>
    </v-card>
    <v-card
      color="transparent"
      flat
      class="d-flex flex-column align-center justify-center home-half-box"
    >
      <v-text-field
        v-model="demoCode"
        label="デモコードをご入力ください"
        clearable
        dense
        prepend-inner-icon="mdi-key"
        class="text-field"
        @change="generateDisabled = false"
      />
      <code class="code-area mb-5">
        {{ embedCode }}
      </code>
      <v-btn
        :disabled="generateDisabled"
        class="button-copy"
        color="orange"
        @click="generateEmbedCode"
      >
        埋め込みコードを生成
      </v-btn>
      <v-btn
        v-clipboard:copy="embedCode"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        :disabled="copyDisabled"
        class="button-copy"
        color="info"
      >
        クリップボードにコピーする
      </v-btn>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="2000">
      {{ message }}

      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { database } from '@/plugins/firebase'

export default {
  name: 'Home',
  data: function() {
    return {
      demoCode: null,
      embedCode: null,
      snackbar: false,
      message: null,
      generateDisabled: true,
      copyDisabled: true,
    }
  },
  methods: {
    generateEmbedCode() {
      database.ref('customersList').once('value', snapshot => {
        const customers = snapshot.val()
        const customerIds = Object.keys(customers)
        if (customerIds.includes(this.demoCode)) {
          this.generateDisabled = true
          this.embedCode = `<div style="box-shadow:0 1.9px 2.5px rgba(0, 0, 0, 0.057),0 5px 6.1px rgba(0, 0, 0, 0.076),0 10.1px 11.4px rgba(0, 0, 0, 0.086),0 19.2px 19.8px rgba(0, 0, 0, 0.092),0 38.4px 34.8px rgba(0, 0, 0, 0.1),0 101px 74px rgba(0, 0, 0, 0.13);height:400px;width:300px;background-color:white;border-radius:5px;margin:50px;padding:0;display:float;"><img src="https://firebasestorage.googleapis.com/v0/b/intella-demo-app/o/staticFiles%2Fintella-video.gif?alt=media&token=005be5a5-7519-46df-b5a5-675f0c794df6" style="width:300px;height:250px;margin:0;border-radius:5px 5px 0 0;" /><div style="width:300px;height:150px;padding:0px;margin:0px;display:flex;flex-direction:column;align-items:center;justify-content:center;"><a href="javascript:void(0);" onClick="window.open('${location.host}/skillcheck/${this.demoCode}','sub','scrollbars=no,toolbar=no,menubar=no')" style="width:200px;height:40px;line-height:40px;display:flex;align-items:center;justify-content:center;color:white;background-color:#1791b1;border-radius:25px;text-decoration:none;margin-bottom:10px;"><h3>Check your skill</h3></a><a href="javascript:void(0);" onClick="window.open('${location.host}/interview/${this.demoCode}','sub','scrollbars=no,toolbar=no,menubar=no')" style="width:200px;height:40px;line-height:40px;display:flex;align-items:center;justify-content:center;color:white;background-color:#d41876;border-radius:25px;text-decoration:none;"><h3>Meet InteLLA</h3></a></div></div>`
          this.copyDisabled = false
          this.message = '埋め込みコードを生成しました！'
          this.snackbar = true
        } else {
          this.message =
            '何かがおかしいようです。再度デモコードをご確認ください。'
          this.snackbar = true
        }
      })
    },
    onCopy() {
      this.message = 'クリップボードにコピーしました！'
      this.snackbar = true
    },
    onError() {
      this.message = '何かがおかしいようです。再度デモコードをご確認ください。'
      this.snackbar = true
    },
    openSkillCheckWindow() {
      if (this.demoCode) {
        window.open(
          'https://' + location.host + '/skillcheck/' + this.demoCode,
          'sub',
          'scrollbars=no,toolbar=no,menubar=no'
        )
      } else {
        this.message = '右のコードをご入力ください。'
        this.snackbar = true
      }
    },
    openInterviewWindow() {
      if (this.demoCode) {
        window.open(
          'https://' + location.host + '/interview/' + this.demoCode,
          'sub',
          'scrollbars=no,toolbar=no,menubar=no'
        )
      } else {
        this.message = '右のコードをご入力ください。'
        this.snackbar = true
      }
    },
  },
}
</script>

<style scoped>
.full-height-width-card {
  min-height: 100vh;
  width: 100vw;
  padding-top: 120px;
}

.text-field {
  width: 300px;
}

.code-area {
  height: 350px;
  width: 300px;
  overflow: scroll;
}

.button-copy {
  width: 300px;
  margin-bottom: 20px;
}

.home-half-box {
  margin: 20px;
  width: 350px;
  height: 500px;
}
</style>
